import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "@/components/section-title";
// import { FeatureTabData } from "@/data";
import featureTab01 from "@/images/date1.jpg";
import featureTab02 from "@/images/date4.png";
import featureTab03 from "@/images/date2.jpg";
import featureTab04 from "@/images/date3.png";

const FeatureTabOne = () => {
  const [active, setActive] = useState(1);
  // const { sectionContent, posts } = FeatureTabData;

  const FeatureTabData = {
    sectionContent: {
      title: "Why Choose Our Medjool Dates",
      subTitle: "our benefits",
    },
    posts: [
      {
        title: "Quality Beyond Compare",
        content:
          "Our Medjool Dates are handpicked with meticulous care, ensuring that only the largest, juiciest, and most delectable fruits make it to your table. Each date represents a masterpiece of nature's finest craftsmanship, cultivated with dedication and passion from Jordan's fertile lands. We take pride in delivering a premium product that embodies excellence and invites you to experience the unmatched richness of Jordan's Medjool Dates firsthand.",
        image: featureTab01,
      },
      {
        title: "Authentic Jordanian Flavor",
        content:
          "Grown in the heart of Jordan's sun-soaked deserts, our dates absorb the essence of the region's unique climate and soil. This natural environment imbues them with a taste that transports you to the serene landscapes of Jordan with every bite. Experience the authentic flavors of Jordan's Medjool Dates, where nature's harmony meets exceptional taste.",
        image: featureTab02,
      },
      {
        title: "Packed with Nutrients",
        content:
          "Medjool Dates are nature's gift of health and vitality. These power-packed fruits are a rich source of dietary fiber, essential minerals, and natural sugars. Indulge guilt-free while nourishing your body with the wholesome goodness of Jordan's finest Medjool Dates.",
        image: featureTab03,
      },
      {
        title: "Versatile Delicacy",
        content:
          "From snacking to baking, our Medjool Dates are as versatile as they are delicious. Whether incorporated into culinary creations or enjoyed as a wholesome snack on their own, the possibilities are endless with Jordan's finest Medjool Dates.",
        image: featureTab04,
      },
    ],
  };

  return (
    <section id="why" className="majdoolchooseUs">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <SectionTitle data={FeatureTabData.sectionContent} />
          </Col>
        </Row>
        <div id="tabs">
          <Row>
            <Col lg={12}>
              <ul className="majdoolchooseUs_title">
                {FeatureTabData.posts.map(({ title }, index) => (
                  <li
                    key={index}
                    className={`${active === index ? "active" : " "}`}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setActive(index);
                      }}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="tab-content">
            {FeatureTabData.posts.map((post, index) => {
              return index === active ? (
                <div
                  className="tab-pane fade show active animated fadeIn"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row
                    className={`${
                      0 === index % 2 ? " " : "flex-lg-row-reverse"
                    }`}
                  >
                    <Col lg={7}>
                      <div className="wh_choose">
                        <p>{post.content}</p>
                        {/* <ul>
                          {post.lists.map(({ item }, index) => (
                            <li key={index}>
                              <i className="fa fa-check-square"></i> {item}
                            </li>
                          ))}
                        </ul> */}
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="chose_img">
                        <img src={post.image} alt={`chose_img-${index}`} />
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeatureTabOne;
